const quizzes = [
  {
    quizId: "testQuiz",
    numContestants: 10, // if percentile is less than threshold, return fail
    questions: [
      {
        questionId: "1",
        isFinalQuestion: false,
        questionText: "What color is the mole's hair?",
        options: ["Brown", "Gray", "Blonde", "Black"],
        //answer: "Brown",
      },
      {
        questionId: "2",
        isFinalQuestion: false,
        questionText: "What team was the mole on for the first mission?",
        options: ["team slingshot", "team tower", "team final defense"],
        //answer: "team slingshot",
      },
      {
        questionId: "3",
        isFinalQuestion: false,
        questionText: "Did the mole successfully eliminate an intruder?",
        options: ["Yes", "No"],
        //answer: "No",
      },
      {
        questionId: "4",
        isFinalQuestion: false,
        questionText:
          "What was the foremost color of the mole's outfit during the mission?",
        options: ["Yellow", "Red", "Blue", "Grey", "Multi-colored"],
        //answer: "Grey",
      },
      {
        questionId: "7",
        isFinalQuestion: false,
        questionText:
          "Who was in the mole's car when leaving the field after the first mission?",
        options: [
          "ryan, sean, muna, mel",
          "jen, tony, hannah, andy",
          "neesh, michael, deanna, quaylyn",
        ],
        //answer: "ryan, sean, muna, mel",
      },
      {
        questionId: "5",
        isFinalQuestion: false,
        questionText: "What gender is the mole?",
        options: ["Male", "Female"],
        //answer: "Male",
      },
      {
        questionId: "6",
        isFinalQuestion: false,
        questionText: "Did the mole enter the phone booth?",
        options: ["Yes", "No"],
        //answer: "No",
      },
      {
        questionId: "8",
        isFinalQuestion: false,
        questionText:
          "Did the mole lie about what action they took in the phone booth?",
        options: ["Yes", "No"],
        //answer: "No",
      },
      {
        questionId: "9",
        isFinalQuestion: false,
        questionText: "Where region of the USA is the mole from?",
        options: [
          "northeast",
          "southeast",
          "midwest",
          "southwest",
          "northwest",
        ],
        //answer: "southwest",
      },
      {
        questionId: "10",
        isFinalQuestion: true,
        questionText: "Who is the mole?",
        options: [
          "deanna",
          "Andy",
          "Hannah",
          "Jen",
          "Melissa",
          "michael",
          "muna",
          "quaylyn",
          "ryan",
          "sean",
          "tony",
          "neesh",
        ],
      },
    ],
  },
];
export default quizzes;
