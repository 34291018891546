import "./App.css";
import quizzes from "./quizData";
import { useState, useEffect, useRef } from "react";

export default function App() {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1);
  const [selectedOption, setSelectedOption] = useState(null);
  // TODO: if "" need to select the quiz (season, episode/contestants)
  const [currentQuizId, setCurrentQuizId] = useState("testQuiz");
  const [passPercentile, setPassPercentile] = useState(10);
  const [questions, setCurrentQuestions] = useState([]);
  useEffect(() => {
    const foundQuiz = quizzes.find((quiz) => {
      return quiz.quizId == currentQuizId;
    });
    setCurrentQuestions(foundQuiz ? foundQuiz.questions : []);
    setPassPercentile(100 / foundQuiz.numContestants);
  });
  const [name, setName] = useState("");
  const [userId, setUserId] = useState("");
  const [answers, setAnswers] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [result, setResult] = useState(false); // true if pass
  const [isClicked, setIsClicked] = useState(false); // true if pass
  const inputRef = useRef(null);

  const handleChange = (e) => {
    let { value } = e.target;
    if (!value) {
      // for final question, need to add name to answers, but its not on the e
      value = e.target.alt;
    }
    setSelectedOption(value);
    setAnswers({
      ...answers,
      [questions[currentQuestionIndex].questionId]: value,
    });
  };

  const handleClick = () => {
    setIsClicked(true);
  };

  function gen4dig() {
    return Math.floor(1000 + Math.random() * 9000);
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleNext = () => {
    if (currentQuestionIndex == -1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setUserId(`${name}-${gen4dig()}`);
    } else if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setSelectedOption(null);
    } else {
      handleSubmit();
    }
  };

  const handleNameInputChange = (e) => {
    setName(e.target.value?.toUpperCase());
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const response = await fetch(
        `https://mole-quiz.stewart-schrieffer71.workers.dev?userId=${userId}&quizId=${currentQuizId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(answers),
        },
      );
      const data = await response.json();
      console.log("Result: " + data?.result);
      console.log(data?.result === "pass");
      setResult(data?.result === "pass");
      console.log("Success:", data);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsSubmitting(false);
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  return (
    <>
      <div className="bg-black text-white min-h-screen flex flex-col items-center justify center w-full">
        {currentQuestionIndex < questions?.length ? (
          currentQuestionIndex == -1 ? (
            <>
              <h1 className="text-xl pt-2">THE MOLE</h1>
              {name.length >= 3 && (
                <div className="fixed bottom-4 right-4">
                  <button
                    onClick={handleNext}
                    className="mt-4 p-2 bg-red-600 rounded-full min-w-24 transition hover:scale-110 transition-transform duration-200"
                  >
                    {currentQuestionIndex < questions.length - 1
                      ? "Next"
                      : "Submit"}
                  </button>
                </div>
              )}
              <div className="flex items-center justify-center h-[80vh] bg-black">
                <div className="flex items-center space-x-4 max-w-[25rem]">
                  <h1 className="text-red-600 text-2xl md:text-4xl border-r-16 border-red-600">
                    NAME:
                  </h1>
                  <input
                    type="text"
                    value={name}
                    onChange={handleNameInputChange}
                    className="bg-black border-b-2 border-white text-white outline-none focus:ring-0 placeholder-transparent text-2xl md:text-4xl text-left uppercase w-[50vw]"
                    placeholder="Enter your name"
                    style={{ caretColor: "white", fontSize: "16px" }}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="bg-black text-white text-center py-2 w-full">
                <h1 className="text-xl pt-2">THE MOLE</h1>
              </div>
              <div className="bg-red-600 text-center w-full">
                <h1 className="text-2xl font-bold my-6">
                  {questions[currentQuestionIndex].questionText.toUpperCase()}
                </h1>
              </div>
              {questions[currentQuestionIndex].isFinalQuestion ? (
                <div className="flex flex-row items-center justify-center flex-grow flex-wrap gap-8 w-full lg:w-4/5 max-h-[90vh] mx-auto overflow-y-auto overflow-x-hidden pt-8 md:pt-20 pb-32 md:pb-8">
                  {questions[currentQuestionIndex].options.map(
                    (image, index) => (
                      <div key={index}>
                        <div
                          className={`text-center ${selectedOption === image ? "bg-red-600" : ""} hover:bg-red-600 hover:z-10 transform hover:scale-110 transition-transform duration-200 cursor-pointer flex flex-col items-center`}
                          onClick={handleChange}
                        >
                          <div className="relative w-32 h-32 md:w-48 md:h-48">
                            <img
                              src={`mole-pfp/the-mole-${image.toLowerCase()}.webp`}
                              alt={image}
                              className="top-0 left-[-10%] w-[120%] h-full object-cover"
                            />
                          </div>
                          <div className="text-white border border-white w-full">
                            {image.toUpperCase()}
                          </div>
                        </div>
                      </div>
                    ),
                  )}
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center flex-grow h-full">
                  <div className="mb-8">
                    {questions[currentQuestionIndex]?.options?.map(
                      (option, index) => (
                        <label
                          key={index}
                          className={`custom-radio block mb-4 p-2 border cursor-pointer rounded-xl text-left text-sm md:text-lg min-w-32 hover:bg-red-600 transform hover:scale-110 transition-transform duration-200 ${
                            selectedOption === option ? "bg-red-600" : ""
                          }`}
                        >
                          <input
                            type="radio"
                            name={questions[currentQuestionIndex].questionId}
                            value={option}
                            checked={selectedOption === option}
                            onChange={handleChange}
                            className="mr-1"
                          />
                          <span className="flex items-center justify-center align-middle"></span>
                          {option?.toUpperCase()}
                        </label>
                      ),
                    )}
                  </div>
                </div>
              )}
              {answers[questions[currentQuestionIndex].questionId] !==
                undefined && (
                <div className="fixed bottom-4 right-4">
                  <button
                    onClick={handleNext}
                    className="mt-4 p-2 bg-red-600 rounded-full min-w-24 transition hover:scale-110 transition-transform duration-200"
                  >
                    {currentQuestionIndex < questions.length - 1
                      ? "Next"
                      : "Submit"}
                  </button>
                </div>
              )}
            </>
          )
        ) : (
          <>
            {!isClicked ? (
              <div className="flex flex-col items-center justify-center h-screen bg-black text-white">
                <div className="relative inline-block">
                  <div
                    className="absolute inset-0 border-2 border-red-600 rounded-full animate-ripple cursor-pointer"
                    onClick={handleClick}
                  ></div>
                  <button
                    className="text-4xl cursor-pointer"
                    onClick={handleClick}
                  >
                    {name}
                  </button>
                </div>
              </div>
            ) : (
              <div
                className={`flex justify-center flex-col items-center h-screen w-full ${
                  !result
                    ? "bg-red-600 animate-backgroundFadeRed"
                    : "bg-[#00FF00] animate-backgroundFadeGreen"
                }`}
              >
                <div className="bg-gray-800 bg-opacity-50 opacity-0 rounded-lg p-4 animate-zipIn">
                  <div className="text-2xl font-bold">
                    {result ? "PASS" : "FAIL"}
                  </div>
                </div>
                <div className="bg-gray-800 bg-opacity-50 rounded-lg p-4 mt-4 text-center animate-fadeIn opacity-0 max-w-[80%]">
                  <div className="text-lg">
                    {result
                      ? `You answered enough questions correctly to outscore ${passPercentile}% of responses.`
                      : `Your score placed in the bottom ${passPercentile}% of all responses.`}
                  </div>
                  <div className="text-lg mt-8">
                    {result
                      ? `You remain in the competition.`
                      : `You've been eliminated.`}
                  </div>
                  <button
                    className="mt-4 bg-red-600 text-white py-2 px-4 rounded-lg hover:bg-red-800"
                    onClick={() => alert("Share to social media!")}
                  >
                    Share on Social Media
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}
